<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew()'>新建</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table
        ref='table'
        rowKey='id'
        :scroll='{ x: 1200}'
        :columns='columns'
        :data='loadData'>
      <span slot='cover' slot-scope='text'>
        <template>
          <img :src='text' width='180px'>
        </template>
      </span>
        <span slot='stitle' slot-scope='text'>
        <template>
          <ellipsis :length='20' tooltip>{{ text }}</ellipsis>
        </template>
      </span>
      <span slot='status' slot-scope='text'>
        <template>
          <a-badge v-if='text=="0"' dot status='default' :text='"下架"' />
          <a-badge v-if='text=="1"' dot status='success' :text='"上架"' />
        </template>
      </span>
      <span slot='type' slot-scope='text'>
        <template v-if='text=="1"'>打开链接</template>
        <template v-if='text=="2"'>普通商品</template>
        <template v-if='text=="3"'>内容编辑</template>
        <template v-if='text=="4"'>分享广告</template>
        <template v-if='text=="5"'>试用商品</template>
      </span>
      <!-- <span slot='contentRichTextUrl' slot-scope='text'>
        <template>
           <a-space>
            <a-button type='primary' shape='circle' size='small' icon='eye' @click='handleView(text)' />
            <a-button type='primary' shape='circle' size='small' icon='copy' v-clipboard:copy='text' />
         </a-space>
        </template>
      </span> -->
        <span slot='action' slot-scope='text, record'>
        <template>
          <a-space>
            <a @click='handleEdit(record)'>编辑</a>
            <a v-if='record.status=="0"' @click='handleStatus(record,1)'>上架</a>
            <a v-if='record.status=="1"' @click='handleStatus(record,0)'>下架</a>
            <a @click='handleDelete(record)'>删除</a>
          </a-space>
        </template>
      </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryMiniAd, putMiniAd, deleteMiniAd } from '@/api/advertise'

const columns = [
  {
    title: '广告名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title' }
  },
  {
    title: '广告封面',
    dataIndex: 'cover',
    scopedSlots: { customRender: 'cover' }
  },
  // {
  //   title: '广告链接地址',
  //   dataIndex: 'contentRichTextUrl',
  //   scopedSlots: { customRender: 'contentRichTextUrl' }
  // },
  {
    title: '广告类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '广告状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'MiniAdvertise',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        return queryMiniAd(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {

  },
  methods: {
    handleNew() {
      this.$router.push('/operation/mini/edit')
    },
    handleEdit(record) {

      this.$router.push({ path: '/operation/mini/edit', query: { id: record.id } })
    },
    handleStatus(record, status) {
      putMiniAd(Object.assign({}, record, { status: status })).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      deleteMiniAd({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleView(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
